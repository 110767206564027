<template>
  <div>
    <!-- Caricamento foto -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Caricamento Foto {{ title }}</strong>
          </CCardHeader>

          <vue-dropzone
            :options="dropzoneOptions"
            :useCustomSlot="true"
            ref="myVueDropzone"
            @vdropzone-success="filesAdded"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">Carica qui le tue foto</h3>
              <div class="subtitle">Trascina o clicca per caricarle</div>
            </div>
          </vue-dropzone>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fine Caricamento Foto -->

    <!-- Elenco foto -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Elenco Foto</strong>
          </CCardHeader>
          <CCardBody>
            <!-- Mostro tutte le foto del gruppo di uploadcare -->
            <CCol class="custom-elenco-foto" md="4" v-for="(item, index) in imgList" :key="item.id">
              <CCard>
                <!-- Anteprima della immagine -->
                <!-- @click="showViewPhoto(item)" -->
                <CCardBody class="custom-body-totem">
                  <img
                    class="custom-img"
                    :src="item.src"
                    width="100%"
                    height="auto"
                    @click="editPhoto(item, index)"
                  />

                  <CButton
                    shape="square"
                    variant="ghost"
                    class="custom-button-delete"
                    v-on:click="editPhoto(item, index)"
                    type="submit"
                    size="lg"
                    color="warning"
                  >
                    <CIcon name="cil-pencil" />
                    Modifica
                  </CButton>

                  <!-- Cancellazione della immagine -->
                  <CButton
                    shape="square"
                    variant="ghost"
                    class="custom-button-delete"
                    @click="deletePhoto(index)"
                    type="submit"
                    size="lg"
                    color="danger"
                  >
                    <CIcon name="cil-x-circle" />
                    Elimina
                  </CButton>
                </CCardBody>
              </CCard>
            </CCol>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fine elenco foto -->

    <CModal
      title="Gestione Foto"
      color="white"
      size="xl"
      fade
      :closeOnBackdrop="false"
      centered
      :show.sync="uploadPhotoModal"
    >
      <div class="upload-example">
        <cropper
          class="upload-example-cropper"
          :src="image"
          ref="cropper"
          :stencil-props="{
            aspectRatio: this.aspectRatioVariable,
          }"
        />
      </div>

      <CButton
        class="custom-botton-save-and-end-edit-modal"
        v-on:click="crop"
        type="submit"
        shape="square"
        size="lg"
        color="info"
      >
        Clicca per ritagliare e salvare
      </CButton>
    </CModal>

    <!-- Modale che mostra l'anteprima dell'immagine -->
    <CModal
      class="modal-show-image"
      color="white"
      size="xl"
      fade
      :closeOnBackdrop="false"
      centered
      :show.sync="viewPhotoModal"
    >
      <img :src="image" width="100%" height="auto" />
    </CModal>
    <!-- Fine modale che mostra anteprima dell'immagine -->
  </div>
</template>

<script>
import UploadClient from '@uploadcare/upload-client';
import { Cropper, Preview } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import VueUploadcare from 'vue-uploadcare';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  components: {
    VSwatches,
    VueUploadcare,
    Cropper,
    Preview,
    vueDropzone: vue2Dropzone,
  },
  data: function () {
    return {
      imgList: [],
      uploadPhotoModal: false,
      showPhotoModal: false,
      viewPhotoModal: false,
      current_images: null,
      aspectRatioVariable: 1.34,
      img_azienda: '',
      current_index_images: null,
      image: null,
      uploadCarePublicKey: '83237effd4da9f06ef10',
      clientUploadCare: new UploadClient({ publicKey: '83237effd4da9f06ef10' }),
      dropzoneOptions: {
        url: 'https://api.funer24.com/post',
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        resizeQuality: 0.1,
        chunking: true,
        resizeMethod: 'crop',
        resizeWidth: 1200,
        resizeHeight: 1200,
        forceChunking: true,
        retryChunks: true,
        retryChunksLimit: 5,
        maxFiles: 1,
      },
    };
  },
  methods: {
    groupUploader() {
      try {
        if (this.imgList.length > 0) {
          let listUuid = [];
          console.log(this.imgList);
          for (const obj of this.imgList) listUuid.push(obj.uuid);
          return Promise.resolve(
            this.clientUploadCare.uploadFileGroup(listUuid).then((obj) => {
              this.$emit('getPhoto', obj.cdnUrl);
              this.fillImgUrl(obj.cdnUrl);
              return obj;
            })
          );
        }
      } catch (e) {
        console.error(e);
      }
    },

    photoUploader() {
      const fileToUpload = this.dataURLtoFile(this.image, 'image');
      Promise.resolve(
        this.clientUploadCare
          .uploadFile(fileToUpload)
          .then((obj) => {
            return obj;
          })
          .then((obj) => {
            this.fillImgList(obj);
            this.groupUploader();
            return obj;
          })
      );
    },

    deletePhoto(index) {
      this.$delete(this.imgList, index);
      this.groupUploader();
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    editPhoto(item, index) {
      this.image = item.src;
      this.current_images = item.src;
      this.uploadPhotoModal = true;
      this.current_index_images = index;
    },

    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.image = canvas.toDataURL('image/jpeg', 0.7);
      this.photoUploader();
      this.$delete(this.imgList, this.current_index_images);
      this.showPhotoModal = false;
      this.uploadPhotoModal = false;
    },

    fillImgList(obj) {
      const baseLink = 'https://ucarecdn.com/';
      this.imgList.push({ uuid: obj.uuid, id: obj.file_id, src: baseLink + obj.uuid + '/' });
    },

    getListImage(uuid) {
      axios
        .get(
          'https://upload.uploadcare.com/group/info/?pub_key=' +
            this.uploadCarePublicKey +
            '&group_id=' +
            uuid
        )
        .then(
          (response) => {
            if (response.status === 200) {
              this.imgList = [];
              this.img_azienda = uuid;
              for (const obj of response.data.files) {
                console.log(obj);
                this.fillImgList(obj);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    filesAdded(file, response) {
      const fileToUpload = this.dataURLtoFile(file.dataURL, 'image');

      Promise.resolve(
        this.clientUploadCare
          .uploadFile(fileToUpload)
          .then((obj) => {
            return obj;
          })
          .then((obj) => {
            this.fillImgList(obj);
            this.groupUploader();
          })
      );
      this.$refs.myVueDropzone.removeFile(file);
    },

    fillImgUrl(groupCdnUrl) {
      this.img_azienda = groupCdnUrl;
    },
  },
  watch: {
    img: {
      immediate: true,
      handler() {
        if (this.img !== null) {
          if (this.img.length > 0) {
            if (this.img.includes('https://ucarecdn.com/')) {
              this.img = this.img.replace('https://ucarecdn.com/', '').replace('/', '');
            }
            this.getListImage(this.img);
          }
        }
      },
    },
    title: { immediate: false },
    aspectRatioVariableProps: {
      immediate: true,
      handler() {
        if (this.aspectRatioVariableProps !== null) {
          this.aspectRatioVariable = this.aspectRatioVariableProps;
        }
      },
    },
  },
  props: ['img', 'title', 'aspectRatioVariableProps'],
  mounted() {
    this.imgList = [];
  },
  name: 'UploadMultiplePhotoAndPreview',
};
</script>

<style>
span.d-block.custom-center-totem {
  margin: 0 auto;
}

img.totem {
  opacity: 0.3;
}

img.isActive.totem {
  opacity: revert;
}

.listTotem {
  display: inline-flex;
  margin: 1%;
}

.jumbotron {
  background-color: #ffffffdb;
  padding-top: 16px;
  padding-bottom: 16px;
}

img.totem {
  border: 8px solid #cecece;
  border-radius: 8px;
}

.card {
  background-color: #ffffffdb;
}

.upload-example-cropper {
  border: solid 1px #eee;
  height: 600px;
  width: auto;
  z-index: 99999999;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  background: #3fb37f;
  cursor: pointer;
  transition: background 0.5s;
}

.button:hover {
  background: #38d890;
}

.button input {
  display: none;
}

span.buttonSaveImage {
  margin-left: 2%;
}

button.btn.custom-button-modify.btn-white.btn-lg {
  width: 50%;
}

button.btn.custom-button-remove.btn-danger.btn-lg {
  width: 50%;
}

footer.card-footer.custom-footer-totem {
  padding: 0;
  margin: 0;
  border: 0 !important;
}

.card-body.custom-body-totem {
  margin: 0;
  padding: 0;
}

footer.modal-footer {
  display: none;
}

.modal-body {
  padding: 0;
}

header.modal-header {
  padding: 1%;
}

img.custom-img:hover {
  opacity: 0.5;
}

.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  background: #fff !important;
}

.dropzone-custom-content {
  padding: 80px;
}

h3.dropzone-custom-title {
  font-weight: 900;
  font-size: 3vw;
}

div.custom-elenco-foto.col-md-4 {
  display: inline-flex;
}

button.btn.custom-button-delete {
  width: 50%;
}

button.btn.custom-botton-save-and-end-edit-modal.btn-info.btn-lg.btn-pill {
  width: 100%;
  height: 75px;
  font-weight: 700;
}

button.btn.pressed-reps.btn-info.btn-lg.btn-pill {
  background-color: #f9b115;
}
</style>
